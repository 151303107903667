import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import HomePage from "./pages/Home";
import BuildPorfolioPage from "./pages/Build-Portfolio";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import PrivacyPage from "./pages/Privacy-Policy";
import TemplateSelection from "./components/templateSelection";
import Temp1Home from "./templates/Template01";
import Temp2Home from "./templates/Template02";
import Temp3Home from "./templates/Template03";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgetPassword from "./pages/forgotPage";
import PersonalInfoUpload from "./components/PersonalInforUpload";
import EducationInfoUpload from "./components/EducationInfoUpload";
import ExperienceInfoUpload from "./components/ExperienceInfoUpload";
import SummaryUpload from "./components/SummaryUpload";
import SkillsUpload from "./components/SkillsUpload";
import ProjectsUpload from "./components/ProjectsUpload";
import ServicesUpload from "./components/ServicesUpload";
import Home from "./templates/Template01/pages/Home";
import About from "./templates/Template01/pages/About";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        {/* <Route path="/login" element={<Login />} /> */}

        <Route path="/temp-select" element={<TemplateSelection />} />
        <Route
          path="/build-portfolio"
          element={<PrivateRoute element={BuildPorfolioPage} />}
        />

        <Route path="/personal-info-upload" element={<PersonalInfoUpload />} />
        <Route path="/education-upload" element={<EducationInfoUpload />} />
        <Route path="/experience-upload" element={<ExperienceInfoUpload />} />
        <Route path="/summary-upload" element={<SummaryUpload />} />
        <Route path="/skills-upload" element={<SkillsUpload />} />
        <Route path="/projects-upload" element={<ProjectsUpload />} />
        <Route path="/services-upload" element={<ServicesUpload />} />

        <Route path="/1/:userId" element={<Temp1Home />} />

        {/* <Route path="/:name" element={<Temp1Home />} /> */}

        <Route path="/temp1-home" element={<Temp1Home />} />
        {/* <Route path="/temp1-home" element={<Home />} /> */}
        <Route path="/temp1-about" element={<About />} />

        <Route path="/temp2-home" element={<Temp2Home />} />
        <Route path="/temp3-home" element={<Temp3Home />} />
      </Routes>
    </Router>
  );
};

export default App;
