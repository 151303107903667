import React, { useState, useEffect } from "react";
import axios from "axios";

const GetProjects = ({ userId }) => {
  // Accept userId from props
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      if (!userId) {
        setError("User ID is not provided.");
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/project/project/${userId}`
        );

        if (response.status === 200) {
          setProjects(response.data.projects); // Assuming projects are in response.data.projects
        } else {
          setError("Failed to fetch projects.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching projects.");
      }
    };

    fetchProjects();
  }, [userId]); // Depend on userId to refetch when it changes

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="text-center">
      <h2>Projects</h2>
      {projects.length > 0 ? (
        <div>
          {projects.map((project) => (
            <div key={project._id} style={{ marginBottom: "20px" }}>
              <h3>{project.projectName}</h3>
              <img
                src={`http://localhost:3000/uploads/${project.mainImage}`}
                alt={project.projectName}
                width="200"
                height="150"
              />
              <p>
                <strong>Description:</strong> {project.projectShortDescription}
              </p>
              <p>
                <strong>Full Description:</strong>{" "}
                {project.projectFullDescription}
              </p>
              <p>
                <strong>Start Date:</strong>{" "}
                {new Date(project.startDate).toLocaleDateString()}
              </p>
              <p>
                <strong>End Date:</strong>{" "}
                {new Date(project.endDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Project URL:</strong>{" "}
                <a
                  href={project.projectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {project.projectUrl}
                </a>
              </p>
              <div>
                <h4>Project Images:</h4>
                {project.projectMultipleImages.map((image, idx) => (
                  <img
                    key={idx}
                    src={`http://localhost:3000/uploads/${image}`}
                    alt={`Project ${idx}`}
                    width="100"
                    height="100"
                    style={{ marginRight: "10px" }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No projects found.</p>
      )}
    </div>
  );
};

export default GetProjects;
