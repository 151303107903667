import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import GetUserInfo from "../../get-info/getUserInfo";
import GetEducation from "../../get-info/getEducation";
import GetExperience from "../../get-info/getExperience";
import GetSummary from "../../get-info/getSummary";
import GetSkills from "../../get-info/getSkills";
import GetServices from "../../get-info/getServices";
import GetProjects from "../../get-info/getProjects";

const Temp1Home = () => {
  const location = useLocation();
  const { userId: paramUserId } = useParams(); // Get userId from URL params
  const localStorageUserId = localStorage.getItem("userId"); // Get userId from localStorage

  // Define routes
  const route1 = "/temp1-home";
  const route2 = `/template/1/${paramUserId}`;

  // Check if current route matches
  const isRoute1 = location.pathname === route1;
  const isRoute2 = location.pathname === route2;

  // Determine which userId to use
  const userId = isRoute1 ? localStorageUserId : paramUserId;

  // Check if userId exists in localStorage
  const hasUserIdInLocalStorage = !!localStorageUserId;

  useEffect(() => {
    // Log both userIds for debugging
    console.log("User ID from URL params:", paramUserId);
    console.log("User ID from localStorage:", localStorageUserId);
  }, [paramUserId, localStorageUserId]);

  return (
    <div
      className="card p-2 min-h-screen bg-gray-100"
      style={{ margin: "50px" }}
    >
      <div className="flex justify-between items-center mb-4">
        {/* Conditionally render the Edit Details button based on localStorage */}
        {hasUserIdInLocalStorage && (
          <button
            onClick={() => alert("Edit details clicked")}
            className="bg-blue-500 py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Edit Details
          </button>
        )}
      </div>

      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        {/* Debugging: Show both user IDs on the page */}
        <div className="mb-4 text-center text-gray-500">
          <p>User ID from URL params: {paramUserId || "N/A"}</p>
          <p>User ID from localStorage: {localStorageUserId || "N/A"}</p>
        </div>

        {/* Pass userId to child components */}
        <GetUserInfo userId={userId} />
        <hr />

        <GetEducation userId={userId} />
        <hr />

        <GetExperience userId={userId} />
        <hr />

        <GetSummary userId={userId} />
        <hr />

        <GetSkills userId={userId} />
        <hr />

        <GetServices userId={userId} />
        <hr />

        <GetProjects userId={userId} />
      </div>
    </div>
  );
};

export default Temp1Home;
