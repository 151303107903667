import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaBriefcase,
  FaChevronUp,
  FaChevronDown,
  FaSave,
  FaTrash,
  FaEdit,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/ExperienceUpload.css";

const ExperienceUpload = () => {
  const [experience, setExperience] = useState({
    company: "",
    jobtittle: "",
    companyLocation: "",
    startDate: "",
    endDate: "",
    checkbox_value: false,
    experienceDescription: "",
  });

  const [existingExperiences, setExistingExperiences] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [editingExperience, setEditingExperience] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.get(
        `http://localhost:3000/experience/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setExistingExperiences(response.data.experiences || []);
    } catch (error) {
      // toast.error("Failed to fetch experiences. Please try again.");
    }
  };

  const handleInputChange = (field, value) => {
    setExperience({
      ...experience,
      [field]: value,
    });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated.");
      return;
    }

    if (
      !experience.company ||
      !experience.jobtittle ||
      !experience.companyLocation ||
      !experience.startDate ||
      !experience.endDate ||
      !experience.experienceDescription
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    const experienceData = {
      company: experience.company,
      jobtittle: experience.jobtittle,
      companyLocation: experience.companyLocation,
      startDate: experience.startDate,
      endDate: experience.endDate,
      checkbox_value: experience.checkbox_value,
      experienceDescription: experience.experienceDescription,
    };

    try {
      if (isEditing && editingExperience) {
        // PUT request to update the existing experience
        await axios.put(
          `http://localhost:3000/experience/${editingExperience._id}`,
          experienceData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Experience updated successfully!");
      } else {
        // POST request to add a new experience
        const newExperienceData = {
          userId,
          experiences: [experience],
        };
        await axios.post(
          "http://localhost:3000/experience",
          newExperienceData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Experience added successfully!");
      }
      await fetchExperiences();
      setIsEditing(false);
      setEditingExperience(null);
      setExperience({
        company: "",
        jobtittle: "",
        companyLocation: "",
        startDate: "",
        endDate: "",
        checkbox_value: false,
        experienceDescription: "",
      });
    } catch (error) {
      toast.error("Failed to save experience. Please try again.");
    }
  };

  const handleEdit = (exp) => {
    setExperience({
      company: exp.company,
      jobtittle: exp.jobtittle,
      companyLocation: exp.companyLocation,
      startDate: exp.startDate,
      endDate: exp.endDate,
      checkbox_value: exp.checkbox_value,
      experienceDescription: exp.experienceDescription,
    });
    setIsEditing(true);
    setEditingExperience(exp);
    setCollapsed(false); // Open the form for editing
  };

  const handleDelete = async (experienceId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("User is not authenticated.");
      return;
    }

    try {
      await axios.delete(`http://localhost:3000/experience/${experienceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await fetchExperiences();
      toast.success("Experience deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete experience. Please try again.");
    }
  };

  return (
    <div className="center-container">
      {/* <ToastContainer /> */}
      <div className="experience-card">
        <div
          className="experience-header"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="experience-title">
            <FaBriefcase className="icon" /> Work Experience
          </div>
          <div className="experience-actions">
            {collapsed ? (
              <FaChevronDown className="icon" />
            ) : (
              <FaChevronUp className="icon" />
            )}
          </div>
        </div>

        <div className={`collapse ${collapsed ? "" : "show"}`}>
          <div className="card-body">
            <form>
              {/* Existing Experiences */}
              <div className="existing-experiences">
                {existingExperiences.length > 0 &&
                  existingExperiences.map((exp) => (
                    <div key={exp._id} className="existing-experience-card">
                      <div className="experience-title">
                        {exp.jobtittle} at {exp.company}
                      </div>
                      <div className="experience-actions">
                        <FaEdit
                          className="icon"
                          onClick={() => handleEdit(exp)}
                        />
                        <FaTrash
                          className="icon"
                          onClick={() => handleDelete(exp._id)}
                        />
                      </div>
                      {/* <p>{exp.experienceDescription}</p> */}
                    </div>
                  ))}
              </div>
              {/* Company, Job Title, and Location */}
              <div className="row">
                <div className="col">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder="e.g. Tech Solutions"
                    value={experience.company}
                    onChange={(e) =>
                      handleInputChange("company", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <label htmlFor="jobtittle">Job Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="jobtittle"
                    placeholder="e.g. Software Engineer"
                    value={experience.jobtittle}
                    onChange={(e) =>
                      handleInputChange("jobtittle", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="companyLocation">Company Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyLocation"
                  placeholder="e.g. New York, NY"
                  value={experience.companyLocation}
                  onChange={(e) =>
                    handleInputChange("companyLocation", e.target.value)
                  }
                />
              </div>

              {/* Dates */}
              <div className="row">
                <div className="col">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={experience.startDate}
                    onChange={(e) =>
                      handleInputChange("startDate", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    value={experience.endDate}
                    onChange={(e) =>
                      handleInputChange("endDate", e.target.value)
                    }
                  />
                </div>
              </div>

              {/* Description */}
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="experienceDescription"
                  value={experience.experienceDescription}
                  onChange={(e) =>
                    handleInputChange("experienceDescription", e.target.value)
                  }
                />
                <label htmlFor="experienceDescription">Description</label>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                <FaSave className="icon" /> {isEditing ? "Update" : "Save"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceUpload;
