import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaTachometerAlt,
  FaCog,
  FaChevronUp,
  FaChevronDown,
  FaLightbulb,
  FaSave,
} from "react-icons/fa";
import "./css/SummaryUpload.css";

const ProfessionalSummary = () => {
  const [summaries, setSummaries] = useState([
    {
      description: "",
    },
  ]);
  const [message, setMessage] = useState("");
  const [collapsed, setCollapsed] = useState([true]);

  const handleInputChange = (index, field, value) => {
    const newSummaries = [...summaries];
    newSummaries[index][field] = value;
    setSummaries(newSummaries);
  };

  const toggleCollapse = (index) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  const handleSave = async (index) => {
    const summary = summaries[index];

    if (!summary.description) {
      toast.error("Please provide a description.");
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated.");
      return;
    }

    const summaryData = {
      userId,
      summaryText: summary.description,
    };

    try {
      await axios.post("http://localhost:3000/summary", summaryData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Summary saved successfully!");
    } catch (err) {
      toast.error("Failed to save summary. Please try again.");
    }
  };

  return (
    <div className="center-container">
      {message && <p className="alert alert-info">{message}</p>}
      {summaries.map((summary, index) => (
        <div key={index} className="summary-card">
          <div className="summary-header" onClick={() => toggleCollapse(index)}>
            <div className="summary-title">
              <FaTachometerAlt className="icon" />
              Professional Summary
            </div>
            <div className="summary-actions">
              <FaCog className="icon settings-icon" />
              {collapsed[index] ? (
                <FaChevronDown className="icon" />
              ) : (
                <FaChevronUp className="icon" />
              )}
            </div>
          </div>

          <div className={`collapse ${collapsed[index] ? "" : "show"}`}>
            <div className="card-body">
              <form>
                {/* Description */}
                <div className="row">
                  <div className="col">
                    <label htmlFor={`description-${index}`}>Description</label>
                    <textarea
                      className="form-control form-input form-select"
                      id={`description-${index}`}
                      placeholder="Write a professional summary about you..."
                      value={summary.description}
                      onChange={(e) =>
                        handleInputChange(index, "description", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* Tips and Save Buttons */}
                <div className="row mt-3">
                  <div className="col d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn"
                      style={{ border: "solid" }}
                    >
                      <FaLightbulb className="icon" /> Tips
                    </button>
                    <div className="">
                      <button
                        type="button"
                        className="btn"
                        style={{ border: "solid" }}
                        onClick={() => handleSave(index)}
                      >
                        <FaSave className="icon" /> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ))}
      <ToastContainer />
    </div>
  );
};

export default ProfessionalSummary;
