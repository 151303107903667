import Navbar from "../components/navbar";
import "../css/home.css"; // Import the CSS file for homepage styling
import homeImage from "../assets/images/homeImage.svg"; // Adjust the path according to your folder structure

const HomePage = () => {
  return (
    <div className="homepage-wrapper">
      <Navbar />
      <div className="homepage-content">
        <div className="text-content">
          <h1 className="heading">Create a</h1>
          <h1 className="heading">Portfolio Website</h1>

          <p className="paragraph">
            Showcase your work online with a stunning portfolio website that not
            only highlights your skills but also elevates your professional
            presence. Our platform offers a wide range of meticulously designed
            templates, crafted to suit various industries and personal styles.
            Whether you're a creative professional, a freelancer, or an
            entrepreneur, you can start building your portfolio with just a few
            clicks.
          </p>
        </div>
        <div className="image-content">
          <img src={homeImage} alt="Home" className="home-image" />
        </div>
      </div>
      <div className="background-circles">
        <div className="circle blue1"></div>
        <div className="circle blue2"></div>
        <div className="circle blue3"></div>
        <div className="circle blue4"></div>
        <div className="circle blue5"></div>
        <div className="circle blue6"></div>

        {/* <div className="circle green"></div> */}
        <div className="circle yellow"></div>
        <div className="circle purple"></div>
        <div className="circle orange"></div>
        {/* <div className="circle cyan"></div>
        <div className="circle magenta"></div> */}
      </div>
    </div>
  );
};

export default HomePage;
