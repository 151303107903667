import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaServicestack,
  FaCog,
  FaChevronUp,
  FaChevronDown,
  FaLightbulb,
  FaTrash,
  FaSave,
  FaEdit,
  FaPlusCircle,
} from "react-icons/fa";
import "./css/ServicesUpload.css";

const ServicesUpload = () => {
  const [skills, setSkills] = useState([
    {
      skillTitle: "",
      proficiency: "",
      description: "",
    },
  ]);
  // const [message, setMessage] = useState("");
  const [collapsed, setCollapsed] = useState([true]);
  const [existingServices, setExistingServices] = useState([]);
  const [editingService, setEditingService] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      // setMessage("User is not authenticated.");
      return;
    }

    try {
      const response = await axios.get(
        `http://localhost:3000/service/service/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Fetched services:", response.data); // Log response data

      setExistingServices(response.data.services || []);
    } catch (err) {
      // setMessage("Failed to fetch services. Please try again.");
    }
  };

  const handleInputChange = (index, field, value) => {
    const newSkills = [...skills];
    newSkills[index][field] = value;
    setSkills(newSkills);
  };

  const toggleCollapse = (index) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  const handleSave = async (index) => {
    const skill = skills[index];

    if (!skill.skillTitle || !skill.proficiency || !skill.description) {
      // setMessage("Please fill in all fields.");
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      // setMessage("User is not authenticated.");
      return;
    }

    const serviceData = {
      userId,
      serviceName: skill.skillTitle,
      serviceDescription: skill.description,
      serviceCategory: skill.proficiency,
    };

    try {
      if (isEditing && editingService) {
        // Update the service
        await axios.put(
          `http://localhost:3000/service/${editingService._id}`,
          serviceData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        // Add new service
        await axios.post("http://localhost:3000/service", serviceData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      }
      // Refetch the services here
      await fetchServices();
      setIsEditing(false);
      setEditingService(null);
    } catch (err) {
      // setMessage("Failed to save service. Please try again.");
    }
  };

  const handleEdit = (service) => {
    setEditingService(service);
    setSkills([
      {
        skillTitle: service.serviceName,
        proficiency: service.serviceCategory,
        description: service.serviceDescription,
      },
    ]);
    setIsEditing(true);
    // Set collapsed to true to show the form
    setCollapsed([false]);
  };

  const handleDelete = async (serviceId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      // setMessage("User is not authenticated.");
      return;
    }

    try {
      await axios.delete(`http://localhost:3000/service/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Refetch services after deletion
      await fetchServices();
      // setMessage("Service deleted successfully!");
    } catch (err) {
      // setMessage("Failed to delete service. Please try again.");
    }
  };

  return (
    <div className="center-container">
      {/* {message && <p className="alert alert-info">{message}</p>} */}

      {/* Skill management section */}
      {skills.map((skill, index) => (
        <div key={index} className="skill-card">
          <div className="skill-header" onClick={() => toggleCollapse(index)}>
            <div className="skill-title">
              <FaServicestack className="icon" />
              Services
            </div>
            <div className="skill-actions">
              <FaCog className="icon settings-icon" />

              {collapsed[index] ? (
                <FaChevronDown className="icon" />
              ) : (
                <FaChevronUp className="icon" />
              )}
            </div>
          </div>

          <div className={`collapse ${collapsed[index] ? "" : "show"}`}>
            <div className="card-body">
              <form>
                <hr />
                {/* Display existing services */}
                {existingServices.length > 0 && (
                  <div className="existing-services">
                    {existingServices.map((service) => (
                      <div key={service._id} className="existing-service-card">
                        <div className="service-title">
                          {service.serviceName}
                        </div>
                        <div className="service-actions">
                          <FaEdit
                            className="icon edit-icon"
                            onClick={() => handleEdit(service)}
                          />
                          <FaTrash
                            className="icon delete-icon"
                            onClick={() => handleDelete(service._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* Skill Title and Proficiency */}

                <div className="row">
                  <div className="col">
                    <label htmlFor={`skillTitle-${index}`}>Service</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`skillTitle-${index}`}
                      placeholder="e.g. react js"
                      value={skill.skillTitle}
                      onChange={(e) =>
                        handleInputChange(index, "skillTitle", e.target.value)
                      }
                    />
                  </div>
                  <div className="col">
                    <label htmlFor={`proficiency-${index}`}>Category</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`proficiency-${index}`}
                      placeholder="e.g. app development"
                      value={skill.proficiency}
                      onChange={(e) =>
                        handleInputChange(index, "proficiency", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* Description */}
                <div className="row">
                  <div className="col">
                    <label htmlFor={`description-${index}`}>Description</label>
                    <textarea
                      className="form-control"
                      id={`description-${index}`}
                      placeholder="Describe your service..."
                      value={skill.description}
                      onChange={(e) =>
                        handleInputChange(index, "description", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* Tips, Save, Delete Buttons */}
                <div className="row mt-3">
                  <div className="col d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        border: "solid",
                      }}
                    >
                      <FaLightbulb className="icon" /> Tips
                    </button>
                    <div className="">
                      <button
                        type="button"
                        className="btn"
                        style={{
                          border: "solid",
                        }}
                        onClick={() => handleSave(index)}
                      >
                        <FaSave className="icon" />{" "}
                        {isEditing ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {/* Add Another Skill Button */}
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="button--grey button--add-section-item"
                  onClick={() => {
                    setSkills([
                      {
                        skillTitle: "",
                        proficiency: "",
                        description: "",
                      },
                    ]);
                    setIsEditing(false);
                    setEditingService(null);
                    setCollapsed([false]); // Show the form
                  }}
                >
                  <span className="icon-circle">
                    <FaPlusCircle className="icon" />
                  </span>
                  {isEditing ? "Update Service" : "Add another Service"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesUpload;
