import React, { useState, useEffect } from "react";
import axios from "axios";

const GetSummary = ({ userId }) => {
  // Accept userId from props
  const [summaryText, setSummaryText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSummary = async () => {
      if (!userId) {
        setError("User ID is not available.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/summary/${userId}` // Use userId from props
        );

        // Access the summaryText from the nested summary object
        setSummaryText(response.data.summary.summaryText);
      } catch (error) {
        setError("Error fetching summary.");
        console.error("Error fetching summary:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [userId]); // Depend on userId to refetch when it changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="text-center">
      <h2>User Summary</h2>
      {summaryText ? <p>{summaryText}</p> : <p>No summary available.</p>}
    </div>
  );
};

export default GetSummary;
