import React, { useState } from "react";
import { FaPlus, FaLightbulb, FaSave } from "react-icons/fa"; // Importing icons
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import "../css/educationInfoUpload.css"; // Assuming a separate CSS file

const EducationInfoUpload = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Initial state for a single education entry
  const initialEducation = {
    degree: "",
    schoolLocation: "",
    schoolName: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    educationDetails: "",
  };

  const [educations, setEducations] = useState([initialEducation]);

  // Handle change for individual education entries
  const handleChange = (index, field, value) => {
    const updatedEducations = [...educations];
    updatedEducations[index][field] = value;
    setEducations(updatedEducations);
  };

  // Handle adding another education entry
  const handleAddAnotherEducation = () => {
    setEducations([...educations, { ...initialEducation }]);
  };

  // Handle saving an education entry (action when "Save" button is clicked)
  const handleSaveEducation = (index) => {
    // Implement your save logic here, such as sending data to the backend or console logging
    console.log("Saved Education:", educations[index]);
  };

  return (
    <div className="education-container">
      {/* Heading */}
      <h1 className="education-heading">Tell us about your Education</h1>

      {/* Subtitle */}
      <p className="education-subtitle">
        You’re onto the next section. Where did you attend college or
        university?{" "}
      </p>

      {/* Education Entries */}
      {educations.map((education, index) => (
        <div key={index} className="education-entry">
          {/* First Row: Degree and School Location */}
          <div className="education-row">
            <div className="education-field">
              <label htmlFor={`degree-${index}`}>Degree</label>
              <input
                type="text"
                id={`degree-${index}`}
                value={education.degree}
                onChange={(e) => handleChange(index, "degree", e.target.value)}
                placeholder="Enter your degree"
              />
            </div>
            <div className="education-field">
              <label htmlFor={`schoolLocation-${index}`}>School Location</label>
              <input
                type="text"
                id={`schoolLocation-${index}`}
                value={education.schoolLocation}
                onChange={(e) =>
                  handleChange(index, "schoolLocation", e.target.value)
                }
                placeholder="Enter your school location"
              />
            </div>
          </div>
          {/* Second Row: School Name */}
          <div className="education-row single-field">
            <div className="education-field">
              <label htmlFor={`schoolName-${index}`}>School Name</label>
              <input
                type="text"
                id={`schoolName-${index}`}
                value={education.schoolName}
                onChange={(e) =>
                  handleChange(index, "schoolName", e.target.value)
                }
                placeholder="Enter your school name"
              />
            </div>
          </div>
          {/* Third Row: Start Date and End Date */}
          <div className="education-row">
            <div className="education-field">
              <label>Start Date</label>
              <div className="date-fields">
                <select
                  value={education.startMonth}
                  onChange={(e) =>
                    handleChange(index, "startMonth", e.target.value)
                  }
                >
                  <option value="">Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  {/* Add other months */}
                </select>
                <select
                  value={education.startYear}
                  onChange={(e) =>
                    handleChange(index, "startYear", e.target.value)
                  }
                >
                  <option value="">Year</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  {/* Add other years */}
                </select>
              </div>
            </div>
            <div className="education-field">
              <label>End Date</label>
              <div className="date-fields">
                <select
                  value={education.endMonth}
                  onChange={(e) =>
                    handleChange(index, "endMonth", e.target.value)
                  }
                >
                  <option value="">Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  {/* Add other months */}
                </select>
                <select
                  value={education.endYear}
                  onChange={(e) =>
                    handleChange(index, "endYear", e.target.value)
                  }
                >
                  <option value="">Year</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  {/* Add other years */}
                </select>
              </div>
            </div>
          </div>
          {/* Fourth Row: Education Details */}
          <div className="education-row single-field">
            <div className="education-field">
              <label htmlFor={`educationDetails-${index}`}>
                Education Details
              </label>
              <textarea
                id={`educationDetails-${index}`}
                rows="5"
                value={education.educationDetails}
                onChange={(e) =>
                  handleChange(index, "educationDetails", e.target.value)
                }
                placeholder="Describe your education details here..."
              ></textarea>
            </div>
          </div>
          {/* Save Button */}
          <div className="save-education-button">
            <button
              onClick={() => handleSaveEducation(index)}
              className="save-button"
            >
              <FaSave className="icon" /> Save Education
            </button>
          </div>
          <hr /> {/* Separator for multiple entries */}
        </div>
      ))}

      {/* Add Another Education Button */}
      <div className="add-education-button">
        <button onClick={handleAddAnotherEducation}>
          <FaPlus className="icon" /> Add Another Education
        </button>
      </div>

      {/* Tips */}
      <div className="tips-section">
        <button className="tips-button">
          <FaLightbulb className="icon" />
          Tips: You can add multiple educational entries if needed.
        </button>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/personal-info-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/experience-upload")}
        >
          Next: Work History
        </button>
      </div>
    </div>
  );
};

export default EducationInfoUpload;
