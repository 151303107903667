import React, { useEffect, useState } from "react";
import axios from "axios";

const GetServices = ({ userId }) => {
  // Accept userId from props
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      if (!userId) {
        setError("User ID not provided.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/service/service/${userId}`
        );

        setServices(response.data.services); // Access the services array from the response
      } catch (error) {
        setError("Failed to fetch services");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [userId]); // Depend on userId to refetch when it changes

  if (loading) {
    return <div>Loading services...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="text-center">
      <h2>Services</h2>
      {services.length > 0 ? (
        <ul>
          {services.map((service) => (
            <li key={service._id}>
              <h3>{service.serviceName}</h3>
              <p>{service.serviceDescription}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No services found.</p>
      )}
    </div>
  );
};

export default GetServices;
