// import UserSpecificNavbar from "../components/userSpecificNavbar";
import { Link } from "react-router-dom"; // Import the Link component

import EducationUpload from "../uploading-info/education";
import ExperienceUpload from "../uploading-info/experience";
import SkillsUpload from "../uploading-info/skills";
import ProfessionalSummary from "../uploading-info/professionalSummary";
import ServicesUpload from "../uploading-info/services";
import ProjectsUpload from "../uploading-info/projects";
import "../css/buildPortfolio.css";
import logo from "../assets/images/pblogo.svg"; // Adjust the path according to your folder structure

const BuildPortfolioPage = () => {
  return (
    <div className="build-portfolio-background">
      <div>
        <h2 className="text-center text-white">
          {" "}
          <img
            src={logo} // Path to the SVG logo
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Portfolio Builder Logo"
          />
          My experiences
        </h2>
        <div className="container mx-auto mt-5 px-4 mb-5">
          <ExperienceUpload />
          <EducationUpload />
          <SkillsUpload />
          <ServicesUpload />
          <ProfessionalSummary />
          <ProjectsUpload />
        </div>
      </div>
      <div className="text-center mt-8 mb-5">
        <Link to="/temp-select">
          <button className="bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded">
            Next Step
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BuildPortfolioPage;
