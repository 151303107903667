import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../css/login.css"; // Make sure you have this CSS file for styling
import AuthImage from "../assets/images/authImage.svg"; // Adjust the path as needed

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:3000/user/signin", {
        email,
        password,
      });

      console.log("Response:", response);

      if (response && response.data && response.data.msg === "User login") {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.userId);

        navigate("/build-portfolio");
      } else if (response && response.data) {
        setError(response.data.msg || "Unexpected error occurred.");
      } else {
        setError("Unexpected error occurred. Please try again.");
      }
    } catch (err) {
      console.error("Error:", err);

      if (err.response) {
        setError(err.response.data.message || "Request failed.");
      } else {
        setError("Network error. Please try again.");
      }
    }
  };

  return (
    <div className="login-container">
      {/* Title */}
      <h1 className="login-title">Portfolio Builder</h1>

      {/* Content Row: Image on left, Form on right */}
      <div className="login-content">
        {/* Image */}
        <img src={AuthImage} alt="Auth Image" className="auth-image" />

        {/* Login Form */}
        <div className="login-form">
          <form onSubmit={handleLogin}>
            <label htmlFor="email" className="login-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="login-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="password" className="login-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="login-input"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            {/* Forgot password and Remember me */}
            <div className="login-forgot-remember">
              <div className="login-checkbox">
                <input type="checkbox" id="rememberMe" />
                <label htmlFor="rememberMe">Remember me</label>
              </div>
              <a href="/forgot-password" className="forgot-password-link">
                Forgot Password?
              </a>
            </div>

            {/* Terms and Conditions */}
            <div className="login-checkbox">
              <input type="checkbox" id="terms" required />
              <label htmlFor="terms">
                I agree to the
                <span className="forgot-password-link"> terms</span> and
                <span className="forgot-password-link"> conditions</span>
              </label>
            </div>

            {/* Login Button */}
            <button type="submit" className="login-btn">
              Login
            </button>

            {/* Sign in with Google */}
            <button className="google-btn">Sign in with Google</button>

            {/* Create Account Link */}
            <p className="login-create-account">
              Don't have an account? <Link to="/signup">Create Account</Link>
            </p>
          </form>

          {/* Display Error Messages */}
          {error && <p className="login-error">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
