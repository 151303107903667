import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/summaryUpload.css"; // Assuming a separate CSS file for styles

const SummaryUpload = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  return (
    <div className="summary-container">
      {/* Heading */}
      <h1 className="summary-heading">Tell us about your Summary</h1>

      {/* Subtitle */}
      <p className="summary-subtitle">
        Choose from our pre-written examples below or write your own.{" "}
      </p>

      {/* Text Editor */}
      <div className="text-editor">
        <textarea
          placeholder="Write your summary here..."
          rows="10"
          cols="50"
        ></textarea>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/experience-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/skills-upload")}
        >
          Next: Skills
        </button>
      </div>
    </div>
  );
};

export default SummaryUpload;
