import React, { useEffect, useState } from "react";
import axios from "axios";

const GetSkills = ({ userId }) => {
  // Accept userId from props
  const [skills, setSkills] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSkills = async () => {
      if (!userId) {
        setError("User ID not provided.");
        return;
      }

      try {
        // Make a GET request to fetch the user's skills
        const response = await axios.get(
          `http://localhost:3000/skill/${userId}`
        );

        // Set the fetched skills to the state
        setSkills(response.data.skills);
      } catch (error) {
        console.error("Error fetching skills:", error);
        setError("Failed to fetch skills. Please try again later.");
      }
    };

    fetchSkills();
  }, [userId]); // Depend on userId to refetch when it changes

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="text-center skills-list">
      <h3>User Skills</h3>
      <ul>
        {skills.map((skill) => (
          <li key={skill._id}>
            <strong>Skill:</strong> {skill.skill} <br />
            <strong>Level:</strong> {skill.level}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GetSkills;
