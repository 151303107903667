import Navbar from "../components/navbar";

const AboutPage = () => {
  return (
    <div>
      <Navbar />
      <h1>AboutPage Page</h1>
    </div>
  );
};

export default AboutPage;
