import React, { useState } from "react";
import axios from "axios";
import "../css/forgetPassword.css"; // Separate CSS file for forget password page
import AuthImage from "../assets/images/authImage.svg"; // Adjust the path as needed

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://localhost:3000/user/forgot-password",
        { email }
      );
      setMessage(response.data.msg);
      setError("");
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.msg || "An error occurred.");
      } else {
        setError("Network error. Please try again.");
      }
      setMessage("");
    }
  };

  return (
    <div className="forget-password-container">
      <h1 className="forget-password-title">Portfolio Builder</h1>
      <div className="forget-password-content">
        <img src={AuthImage} alt="Auth Image" className="auth-image" />
        <div className="forget-password-form">
          <h2 className="form-title">Forget Password</h2>
          <p className="form-description">
            Enter your email address below to receive a link to reset your
            password.
          </p>
          {error && <p className="form-error">{error}</p>}
          {message && <p className="form-success">{message}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              className="form-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="form-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
