import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTrash,
  FaGraduationCap,
} from "react-icons/fa";
import "./css/EducationUpload.css";

const EducationUpload = () => {
  const [education, setEducation] = useState({
    degree: "", // Updated key
    schoolLocation: "", // Updated key
    schoolName: "", // Updated key
    startDate: "",
    endDate: "",
    educationDescription: "", // Updated key
  });

  const [existingEducations, setExistingEducations] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [editingEducation, setEditingEducation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchEducations();
  }, []);

  const fetchEducations = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.get(
        `http://localhost:3000/education/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setExistingEducations(response.data.educations || []);
    } catch (error) {
      toast.error("Failed to fetch educations. Please try again.");
    }
  };

  const handleInputChange = (field, value) => {
    setEducation({
      ...education,
      [field]: value,
    });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated.");
      return;
    }

    if (
      !education.degree ||
      !education.schoolLocation ||
      !education.schoolName ||
      !education.startDate ||
      !education.endDate ||
      !education.educationDescription
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    const educationData = {
      degree: education.degree,
      schoolLocation: education.schoolLocation,
      schoolName: education.schoolName,
      startDate: education.startDate,
      endDate: education.endDate,
      educationDescription: education.educationDescription,
    };

    try {
      if (isEditing && editingEducation) {
        // PUT request to update the existing education
        await axios.put(
          `http://localhost:3000/education/${editingEducation._id}`,
          educationData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Education updated successfully!");
      } else {
        // POST request to add a new education
        const newEducationData = {
          userId,
          education: [educationData],
        };
        await axios.post("http://localhost:3000/education", newEducationData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Education added successfully!");
      }
      await fetchEducations();
      setIsEditing(false);
      setEditingEducation(null);
      setEducation({
        degree: "",
        schoolLocation: "",
        schoolName: "",
        startDate: "",
        endDate: "",
        educationDescription: "",
      });
    } catch (error) {
      toast.error("Failed to save education. Please try again.");
    }
  };

  return (
    <div className="center-container">
      <ToastContainer />
      <div className="education-card">
        <div
          className="education-header"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="education-title">
            <FaGraduationCap className="icon" /> Education and Qualifications
          </div>
          <div className="education-actions">
            {collapsed ? (
              <FaChevronDown className="icon" />
            ) : (
              <FaChevronUp className="icon" />
            )}
          </div>
        </div>

        <div className={`collapse ${collapsed ? "" : "show"}`}>
          <div className="card-body">
            <form>
              {/* Degree Title, City, and School */}
              <div className="row">
                <div className="col">
                  <label htmlFor="degree">Degree Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="degree"
                    placeholder="e.g. Bachelor of Science"
                    value={education.degree}
                    onChange={(e) =>
                      handleInputChange("degree", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <label htmlFor="schoolLocation">City/Town</label>
                  <input
                    type="text"
                    className="form-control"
                    id="schoolLocation"
                    placeholder="e.g. San Francisco"
                    value={education.schoolLocation}
                    onChange={(e) =>
                      handleInputChange("schoolLocation", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="schoolName">School</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolName"
                  placeholder="e.g. UET Lahore"
                  value={education.schoolName}
                  onChange={(e) =>
                    handleInputChange("schoolName", e.target.value)
                  }
                />
              </div>

              {/* Dates */}
              <div className="row">
                <div className="col">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={education.startDate}
                    onChange={(e) =>
                      handleInputChange("startDate", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    value={education.endDate}
                    onChange={(e) =>
                      handleInputChange("endDate", e.target.value)
                    }
                  />
                </div>
              </div>

              {/* Description */}
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="educationDescription"
                  value={education.educationDescription}
                  onChange={(e) =>
                    handleInputChange("educationDescription", e.target.value)
                  }
                />
                <label htmlFor="educationDescription">Description</label>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                <FaSave className="icon" /> {isEditing ? "Update" : "Save"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationUpload;
