import React from "react";

const Temp2Home = () => {
  const userInfo = {
    name: "John Doe",
    profession: "Software Engineer",
    address: "123 Main St, Springfield, USA",
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">{userInfo.name}</h2>
          <p className="text-gray-600">{userInfo.profession}</p>
        </div>
        <div className="text-center mb-4">
          <p className="text-gray-500">{userInfo.address}</p>
        </div>
        <div className="text-center"></div>
      </div>
    </div>
  );
};

export default Temp2Home;
