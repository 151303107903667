import React, { useState } from "react";

const Temp3Home = () => {
  // State to manage the collapse functionality
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Arrays for months and years
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from({ length: 50 }, (_, i) => i + 1970);

  return (
    <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6 px-5 mt-5">
      <div className="card">
        <div className="card-header" id="headingOne">
          <h3
            onClick={toggleCollapse}
            aria-expanded={!isCollapsed}
            aria-controls="collapseOne"
          >
            Education and Qualifications
          </h3>
        </div>

        <div
          id="collapseOne"
          className={`collapse ${isCollapsed ? "" : "show"}`}
          aria-labelledby="headingOne"
        >
          <div className="card-body">
            <hr />
            <form>
              <div className="row">
                <div className="col">
                  <label htmlFor="jobTitle">Job Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="jobTitle"
                    placeholder="e.g. Sales Manager"
                  />
                </div>
                <div className="col">
                  <label htmlFor="city">City/Town</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="e.g. San Francisco"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="employer">Employer</label>
                <input
                  type="text"
                  className="form-control"
                  id="employer"
                  placeholder="e.g. PwC"
                />
              </div>
              <div className="row">
                {/* Dropdown for Start Month */}
                <div className="col">
                  <label htmlFor="startMonth">Start Month</label>
                  <select id="startMonth" className="form-control">
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Dropdown for Start Year */}
                <div className="col">
                  <label htmlFor="startYear">Start Year</label>
                  <select id="startYear" className="form-control">
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Dropdown for End Month */}
                <div className="col">
                  <label htmlFor="endMonth">End Month</label>
                  <select id="endMonth" className="form-control">
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="endYear">End Year</label>
                  <select id="endYear" className="form-control">
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                ></textarea>
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
              <div className="row mt-3">
                <div className="col d-flex justify-content-between">
                  <button type="button" className="btn btn-secondary">
                    Tips
                  </button>
                  <div className="ml-auto">
                    <button type="button" className="btn btn-secondary me-2">
                      Save
                    </button>
                    <button type="button" className="btn btn-secondary">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temp3Home;
