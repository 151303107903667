import Navbar from "../components/navbar";

const PrivacyPage = () => {
  return (
    <div>
      <Navbar />
      <h1>Privacy-Policy Page</h1>
    </div>
  );
};

export default PrivacyPage;
