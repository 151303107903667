import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/signup.css"; // Separate CSS file for signup
import AuthImage from "../assets/images/authImage.svg"; // Adjust the path as needed

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post("http://localhost:3000/user/signup", {
        username,
        email,
        password,
      });

      // Handle successful signup
      setSuccess(response.data.msg);
      console.log(response.data.msg);
      setError("");
      // Redirect to login page after successful signup
      setTimeout(() => {
        navigate("/login");
      }, 2000); // Optional delay to show success message before redirecting
    } catch (err) {
      if (err.response && err.response.data) {
        const { msg } = err.response.data;
        if (msg === "User already exists") {
          setError("User already exists. Please try another email.");
        } else {
          setError(msg || "An error occurred.");
        }
      } else {
        setError("Network error. Please try again.");
      }
      setSuccess("");
    }
  };

  return (
    <div className="signup-container">
      <h1 className="signup-title">Create Your Account</h1>
      <div className="signup-content">
        <img src={AuthImage} alt="Auth Image" className="auth-image" />
        <div className="signup-form">
          {error && <p className="signup-error">{error}</p>}
          {success && <p className="signup-success">{success}</p>}
          <form onSubmit={handleSignup}>
            <label htmlFor="username" className="signup-label">
              Name
            </label>
            <input
              type="text"
              id="username"
              className="signup-input"
              placeholder="Enter your name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <label htmlFor="email" className="signup-label">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="signup-input"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="password" className="signup-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="signup-input"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="confirm-password" className="signup-label">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              className="signup-input"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <div className="signup-checkbox">
              <input type="checkbox" id="terms" />
              <label htmlFor="terms">I agree to the terms and conditions</label>
            </div>
            <button type="submit" className="signup-btn">
              Sign Up
            </button>
            <button className="google-btn">Sign up with Google</button>
            <p className="signup-login-link">
              Already have an account? <a href="/login">Login here</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
