import React, { useState, useEffect } from "react";
import axios from "axios";

const GetExperience = ({ userId }) => {
  // Accept userId from props
  const [experiences, setExperiences] = useState([]); // State for experience data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExperiences = async () => {
      if (!userId) {
        setError("User ID is not available.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/experience/${userId}` // Use userId from props
        );
        setExperiences(response.data.experiences);
      } catch (err) {
        setError("Error fetching experience data.");
      } finally {
        setLoading(false);
      }
    };

    fetchExperiences();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="text-center mb-4">
      <h3 className="text-xl font-bold text-gray-800">Experience</h3>
      {experiences.length > 0 ? (
        experiences.map((exp) => (
          <div key={exp._id} className="mb-4">
            <p className="text-gray-600 font-semibold">{exp.jobTitle}</p>
            <p className="text-gray-500">{exp.company}</p>
            <p className="text-gray-500">{exp.companyLocation}</p>
            <p className="text-gray-500">
              {new Date(exp.startDate).toLocaleDateString()} -{" "}
              {new Date(exp.endDate).toLocaleDateString()}
            </p>
            <p className="text-gray-500">{exp.experienceDescription}</p>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No experience data available.</p>
      )}
    </div>
  );
};

export default GetExperience;
