import Navbar from "../components/navbar";

const ContactPage = () => {
  return (
    <div>
      <Navbar />
      <h1>Contact Page</h1>
    </div>
  );
};

export default ContactPage;
