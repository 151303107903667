import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/skillsUpload.css"; // Assuming a separate CSS file for styles
import { FaPlus, FaSave } from "react-icons/fa"; // Importing icons

const SkillsUpload = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [skills, setSkills] = useState([{ skillName: "", level: "" }]);

  const handleAddSkill = () => {
    setSkills([...skills, { skillName: "", level: "" }]);
  };

  const handleChange = (index, field, value) => {
    const updatedSkills = [...skills];
    updatedSkills[index][field] = value;
    setSkills(updatedSkills);
  };

  const handleSaveSkill = (index) => {
    console.log("Saving skill:", skills[index]);
    // Add logic to save the skill
  };

  return (
    <div className="skills-container">
      {/* Heading */}
      <h1 className="skills-heading">Add Your Skills</h1>

      {/* Subtitle */}
      <p className="skills-subtitle">
        List your skills and expertise level to showcase your qualifications.
      </p>

      {/* Skills Fields */}
      {skills.map((skill, index) => (
        <div key={index} className="skills-row">
          <div className="skills-field">
            <label htmlFor={`skillName-${index}`}>Skill Name</label>
            <input
              type="text"
              id={`skillName-${index}`}
              value={skill.skillName}
              onChange={(e) => handleChange(index, "skillName", e.target.value)}
              placeholder="Enter skill name"
            />
          </div>
          <div className="skills-field">
            <label htmlFor={`level-${index}`}>Level</label>
            <select
              id={`level-${index}`}
              value={skill.level}
              onChange={(e) => handleChange(index, "level", e.target.value)}
            >
              <option value="">Select Level</option>
              <option value="Expert">Expert</option>
              <option value="Experienced">Experienced</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Beginner">Beginner</option>
            </select>
          </div>
          <div className="save-skill-button">
            <button onClick={() => handleSaveSkill(index)}>
              <FaSave className="icon" /> Save
            </button>
          </div>
        </div>
      ))}

      {/* Add Another Skill Button */}
      <div className="add-skill-button">
        <button onClick={handleAddSkill}>
          <FaPlus className="icon" /> Add Another Skill
        </button>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/summary-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/projects-upload")}
        >
          Next: Projects
        </button>
      </div>
    </div>
  );
};

export default SkillsUpload;
