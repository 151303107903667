import React, { useState, useEffect } from "react";
import axios from "axios";

const GetEducation = ({ userId }) => {
  // Accept userId from props
  const [education, setEducation] = useState([]); // State for education data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEducation = async () => {
      if (!userId) {
        setError("User ID is not available.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/education/user/${userId}` // Use userId from props
        );
        setEducation(response.data.education);
      } catch (err) {
        setError("Error fetching education data.");
      } finally {
        setLoading(false);
      }
    };

    fetchEducation();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="text-center mb-4">
      <h3 className="text-xl font-bold text-gray-800">Education</h3>
      {education.length > 0 ? (
        education.map((edu) => (
          <div key={edu._id} className="mb-4">
            <p className="text-gray-600 font-semibold">{edu.degree}</p>
            <p className="text-gray-500">{edu.schoolName}</p>
            <p className="text-gray-500">{edu.schoolLocation}</p>
            <p className="text-gray-500">
              {new Date(edu.startDate).toLocaleDateString()} -{" "}
              {new Date(edu.endDate).toLocaleDateString()}
            </p>
            <p className="text-gray-500">{edu.educationDescription}</p>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No education data available.</p>
      )}
    </div>
  );
};

export default GetEducation;
