import React, { useState } from "react";
import axios from "axios";
import {
  FaProjectDiagram,
  FaCog,
  FaPlusCircle,
  FaChevronUp,
  FaChevronDown,
  FaLightbulb,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/ProjectsUpload.css";

const ProjectsUpload = () => {
  const [experiences, setExperiences] = useState([
    {
      jobTitle: "",
      city: null,
      additionalImages: [],
      projectUrl: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
    },
  ]);
  const [collapsed, setCollapsed] = useState([true]);

  const months = [
    "Select Month",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from({ length: 50 }, (_, i) => i + 1970);

  const handleInputChange = (index, field, value) => {
    const newExperiences = [...experiences];
    newExperiences[index][field] = value;
    setExperiences(newExperiences);
  };

  const handleFileChange = (index, field, event) => {
    const files = Array.from(event.target.files);
    const newExperiences = [...experiences];
    newExperiences[index][field] = files;
    setExperiences(newExperiences);
  };

  const toggleCollapse = (index) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  const handleSave = async (index) => {
    const experience = experiences[index];

    if (
      !experience.jobTitle ||
      !experience.city ||
      !experience.projectUrl ||
      !experience.description
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated.");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("projectName", experience.jobTitle);
    formData.append("projectUrl", experience.projectUrl);
    formData.append("projectShortDescription", experience.shortDescription);
    formData.append("projectFullDescription", experience.description);
    formData.append(
      "startDate",
      `${experience.startYear}-${months.indexOf(experience.startMonth) + 1}-01`
    );
    formData.append(
      "endDate",
      `${experience.endYear}-${months.indexOf(experience.endMonth) + 1}-01`
    );

    experience.additionalImages.forEach((file, i) => {
      formData.append("projectMultipleImages", file);
    });

    if (experience.city[0]) {
      formData.append("mainImage", experience.city[0]);
    }

    try {
      await axios.post("http://localhost:3000/project", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Project saved successfully!");
    } catch (err) {
      toast.error("Failed to save project. Please try again.");
    }
  };

  return (
    <div className="center-container">
      <ToastContainer />
      {experiences.map((experience, index) => (
        <div key={index} className="experience-card">
          <div
            className="experience-header"
            onClick={() => toggleCollapse(index)}
          >
            <div className="experience-title">
              <FaProjectDiagram className="icon" /> Project Details
            </div>
            <div className="experience-actions">
              <FaCog className="icon settings-icon" />
              {collapsed[index] ? (
                <FaChevronDown className="icon" />
              ) : (
                <FaChevronUp className="icon" />
              )}
            </div>
          </div>

          <div className={`collapse ${collapsed[index] ? "" : "show"}`}>
            <div className="card-body">
              <form>
                <hr />
                {/* Project Name and Main Image */}
                <div className="row">
                  <div className="col">
                    <label htmlFor={`jobTitle-${index}`}>Project Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`jobTitle-${index}`}
                      placeholder="e.g. Sales Dashboard"
                      value={experience.jobTitle}
                      onChange={(e) =>
                        handleInputChange(index, "jobTitle", e.target.value)
                      }
                    />
                  </div>
                  <div className="col">
                    <label htmlFor={`mainImage-${index}`}>Main Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id={`mainImage-${index}`}
                      accept="image/*"
                      onChange={(e) => handleFileChange(index, "city", e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label htmlFor={`additionalImages-${index}`}>
                      Additional Images
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id={`additionalImages-${index}`}
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        handleFileChange(index, "additionalImages", e)
                      }
                    />
                  </div>
                  <div className="col">
                    <label htmlFor={`projectUrl-${index}`}>Project URL</label>
                    <input
                      type="url"
                      className="form-control"
                      id={`projectUrl-${index}`}
                      placeholder="e.g. https://example.com/project"
                      value={experience.projectUrl}
                      onChange={(e) =>
                        handleInputChange(index, "projectUrl", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* Dates */}
                <div className="row">
                  <div className="col">
                    <label htmlFor={`startMonth-${index}`}>Start Month</label>
                    <select
                      id={`startMonth-${index}`}
                      className="form-control"
                      value={experience.startMonth}
                      onChange={(e) =>
                        handleInputChange(index, "startMonth", e.target.value)
                      }
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor={`startYear-${index}`}>Start Year</label>
                    <select
                      id={`startYear-${index}`}
                      className="form-control"
                      value={experience.startYear}
                      onChange={(e) =>
                        handleInputChange(index, "startYear", e.target.value)
                      }
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor={`endMonth-${index}`}>End Month</label>
                    <select
                      id={`endMonth-${index}`}
                      className="form-control"
                      value={experience.endMonth}
                      onChange={(e) =>
                        handleInputChange(index, "endMonth", e.target.value)
                      }
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor={`endYear-${index}`}>End Year</label>
                    <select
                      id={`endYear-${index}`}
                      className="form-control"
                      value={experience.endYear}
                      onChange={(e) =>
                        handleInputChange(index, "endYear", e.target.value)
                      }
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Short and Full Description */}
                <div className="form-group">
                  <label htmlFor={`shortDescription-${index}`}>
                    Short Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id={`shortDescription-${index}`}
                    placeholder="e.g. A brief overview of the project"
                    value={experience.shortDescription}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "shortDescription",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`fullDescription-${index}`}>
                    Full Description
                  </label>
                  <textarea
                    className="form-control"
                    id={`fullDescription-${index}`}
                    rows="3"
                    placeholder="e.g. Detailed description of the project"
                    value={experience.description}
                    onChange={(e) =>
                      handleInputChange(index, "description", e.target.value)
                    }
                  />
                </div>
                <div className="row mt-3">
                  <div className="col d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        border: "solid",
                      }}
                    >
                      <FaLightbulb className="icon" /> Tips
                    </button>
                    <div className="">
                      <button
                        type="button"
                        className="btn"
                        style={{
                          border: "solid",
                          marginRight: "5px",
                        }}
                      >
                        <FaTrash className="icon" /> Delete
                      </button>
                      <button
                        type="button"
                        className="btn"
                        style={{
                          border: "solid",
                        }}
                        onClick={() => handleSave(index)}
                      >
                        <FaSave className="icon" /> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {/* Add Another Experience Button */}
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="button--grey button--add-section-item"
                >
                  <span className="icon-circle">
                    <FaPlusCircle className="icon" />
                  </span>
                  Add another work experience
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="add-experience"></div>
    </div>
  );
};

export default ProjectsUpload;
