import React, { useState, useEffect } from "react";
import "../css/personalInfoUpload.css";
import { FaCamera } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PersonalInfoUpload = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    dob: { day: "", month: "", year: "" },
    placeOfBirth: "",
    meritalStatus: "",
    nationality: "",
    gender: "",
  });
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [errors, setErrors] = useState({});
  const [isExistingData, setIsExistingData] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetch(`http://localhost:3000/userInfo/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.userInfo) {
            const {
              profileImage,
              name,
              email,
              address,
              phone,
              dateOfBirth,
              city,
              placeOfBirth,
              nationality,
              gender,
              meritalStatus,
            } = data.userInfo;

            setFormData({
              name: name || "",
              email: email || "",
              phone: phone || "",
              address: address || "",
              city: city || "",
              dob: {
                day: new Date(dateOfBirth).getDate().toString(),
                month: (new Date(dateOfBirth).getMonth() + 1).toString(),
                year: new Date(dateOfBirth).getFullYear().toString(),
              },
              placeOfBirth: placeOfBirth || "",
              meritalStatus: meritalStatus || "",
              nationality: nationality || "",
              gender: gender || "",
            });

            // Populate profile image
            if (profileImage) {
              setSelectedImage(profileImage);
            }

            setIsDataFetched(true);
            setIsExistingData(true); // Set flag to show update and delete buttons
          } else {
            setIsExistingData(false); // No data found, show save button
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setIsExistingData(false); // In case of an error, assume no existing data
        });
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file); // Store file object for upload
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDobChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      dob: { ...prevData.dob, [name]: value },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.dob.day || !formData.dob.month || !formData.dob.year)
      newErrors.dob = "Complete date of birth is required.";
    if (!formData.placeOfBirth)
      newErrors.placeOfBirth = "Place of birth is required.";
    if (!formData.meritalStatus)
      newErrors.meritalStatus = "Marital status is required.";
    if (!formData.nationality)
      newErrors.nationality = "Nationality is required.";
    if (!formData.gender) newErrors.gender = "Gender is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const formDataToSend = new FormData();
    formDataToSend.append("userId", userId || "");
    if (selectedImage) {
      formDataToSend.append("profileImage", selectedImage);
    }
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append(
      "dateOfBirth",
      `${formData.dob.month}/${formData.dob.day}/${formData.dob.year}`
    );
    formDataToSend.append("city", formData.city);
    formDataToSend.append("placeOfBirth", formData.placeOfBirth);
    formDataToSend.append("nationality", formData.nationality);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("meritalStatus", formData.meritalStatus);

    fetch("http://localhost:3000/userInfo/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        navigate("/build-portfolio");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdate = () => {
    if (!validateForm()) return;

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const formDataToSend = new FormData();
    if (selectedImage) {
      formDataToSend.append("profileImage", selectedImage);
    }
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append(
      "dateOfBirth",
      `${formData.dob.month}/${formData.dob.day}/${formData.dob.year}`
    );
    formDataToSend.append("city", formData.city);
    formDataToSend.append("placeOfBirth", formData.placeOfBirth);
    formDataToSend.append("nationality", formData.nationality);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("meritalStatus", formData.meritalStatus);

    fetch(`http://localhost:3000/userInfo/${userId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        navigate("/build-portfolio");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDelete = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (userId) {
      fetch(`http://localhost:3000/userInfo/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then(() => {
          console.log("User data deleted");
          // navigate("/profile");
        })
        .catch((error) => {
          console.error("Error deleting user data:", error);
        });
    }
  };

  return (
    <div className="personal-info-container">
      <h1 className="personal-info-title">
        What’s the best way for employers to contact you?
      </h1>
      <h3 className="personal-info-subtitle">
        We suggest including an email and phone number.
      </h3>

      <div className="image-row row-container">
        <div className="image-picker-container">
          <label className="image-picker">
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected"
                className="selected-image"
              />
            ) : (
              <FaCamera className="camera-icon" />
            )}
            <input
              type="file"
              className="image-input"
              onChange={handleImageChange}
            />
          </label>
        </div>

        <div className="name-email-col">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-input"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>
      </div>

      <div className="phone-address-col row-container">
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phone"
            className="form-input"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            name="address"
            className="form-input"
            placeholder="Enter your address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>
      </div>

      <div className="city-dob-row row-container">
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            className="form-input"
            placeholder="Enter your city"
            value={formData.city}
            onChange={handleInputChange}
            required
          />
          {errors.city && <span className="error">{errors.city}</span>}
        </div>

        <div className="dob-col">
          <label>Date of Birth</label>
          <div className="dob-container">
            <input
              type="text"
              name="day"
              className="dob-input"
              placeholder="Day"
              value={formData.dob.day}
              onChange={handleDobChange}
              required
            />
            <input
              type="text"
              name="month"
              className="dob-input"
              placeholder="Month"
              value={formData.dob.month}
              onChange={handleDobChange}
              required
            />
            <input
              type="text"
              name="year"
              className="dob-input"
              placeholder="Year"
              value={formData.dob.year}
              onChange={handleDobChange}
              required
            />
          </div>
          {errors.dob && <span className="error">{errors.dob}</span>}
        </div>
      </div>

      <div className="form-group">
        <label>Place of Birth</label>
        <input
          type="text"
          name="placeOfBirth"
          className="form-input"
          placeholder="Enter your place of birth"
          value={formData.placeOfBirth}
          onChange={handleInputChange}
          required
        />
        {errors.placeOfBirth && (
          <span className="error">{errors.placeOfBirth}</span>
        )}
      </div>

      <div className="form-group">
        <label>Marital Status</label>
        <input
          type="text"
          name="meritalStatus"
          className="form-input"
          placeholder="Enter your marital status"
          value={formData.meritalStatus}
          onChange={handleInputChange}
          required
        />
        {errors.meritalStatus && (
          <span className="error">{errors.meritalStatus}</span>
        )}
      </div>

      <div className="form-group">
        <label>Nationality</label>
        <input
          type="text"
          name="nationality"
          className="form-input"
          placeholder="Enter your nationality"
          value={formData.nationality}
          onChange={handleInputChange}
          required
        />
        {errors.nationality && (
          <span className="error">{errors.nationality}</span>
        )}
      </div>

      <div className="form-group">
        <label>Gender</label>
        <input
          type="text"
          name="gender"
          className="form-input"
          placeholder="Enter your gender"
          value={formData.gender}
          onChange={handleInputChange}
          required
        />
        {errors.gender && <span className="error">{errors.gender}</span>}
      </div>

      <div className="button-container">
        {!isExistingData ? (
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        ) : (
          <>
            <button className="update-button" onClick={handleUpdate}>
              Update
            </button>
            <button className="delete-button" onClick={handleDelete}>
              Delete
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalInfoUpload;
