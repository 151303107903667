import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaSave } from "react-icons/fa"; // Importing icons
import "../css/servicesUpload.css"; // Assuming a separate CSS file for styles

const ServicesUpload = () => {
  const navigate = useNavigate();

  // State to manage multiple services
  const [services, setServices] = useState([
    {
      serviceName: "",
      serviceField: "",
      serviceDescription: "",
    },
  ]);

  // Add another service
  const handleAddService = () => {
    setServices([
      ...services,
      {
        serviceName: "",
        serviceField: "",
        serviceDescription: "",
      },
    ]);
  };

  // Handle changes for service fields
  const handleChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  // Handle saving of each service
  const handleSaveService = (index) => {
    console.log("Saving service:", services[index]);
    // Add logic to save the service
  };

  return (
    <div className="services-container">
      {/* Heading and Subtitle */}
      <h1 className="services-heading">Add Your Services</h1>
      <p className="services-subtitle">
        List the services you offer and provide details about each.
      </p>

      {/* Services Form */}
      {services.map((service, index) => (
        <div key={index} className="service-entry">
          {/* Service Name and Field */}
          <div className="service-row">
            <div className="service-field">
              <label htmlFor={`service-name-${index}`}>Service Name</label>
              <input
                type="text"
                id={`service-name-${index}`}
                value={service.serviceName}
                onChange={(e) =>
                  handleChange(index, "serviceName", e.target.value)
                }
                placeholder="Enter service name"
              />
            </div>
            <div className="service-field">
              <label htmlFor={`service-field-${index}`}>Service Field</label>
              <input
                type="text"
                id={`service-field-${index}`}
                value={service.serviceField}
                onChange={(e) =>
                  handleChange(index, "serviceField", e.target.value)
                }
                placeholder="Enter service field"
              />
            </div>
          </div>

          {/* Service Description */}
          <div className="service-row">
            <div className="service-field">
              <label htmlFor={`service-description-${index}`}>
                Service Description
              </label>
              <textarea
                id={`service-description-${index}`}
                rows="4"
                value={service.serviceDescription}
                onChange={(e) =>
                  handleChange(index, "serviceDescription", e.target.value)
                }
                placeholder="Provide a detailed description"
              />
            </div>
          </div>

          {/* Save Button for Each Service */}
          <div className="save-service-button">
            <button onClick={() => handleSaveService(index)}>
              <FaSave className="icon" /> Save
            </button>
          </div>

          <hr />
        </div>
      ))}

      {/* Add Another Service Button */}
      <div className="add-service-button">
        <button onClick={handleAddService}>
          <FaPlus className="icon" /> Add Another Service
        </button>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/projects-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/select-themes")}
        >
          Next: Select Themes
        </button>
      </div>
    </div>
  );
};

export default ServicesUpload;
