import "../css/About.css";
import Navbar from "../components/navbar.jsx";
import profile from "../css/me.jpeg";

const About = () => {
  return (
    <div className="about-container">
      <Navbar linkColor="#6A6A6A" />
      <header className="about-header">
        <h1>About Me</h1>
        <p className="slogan">"Crafting Beautiful Digital Experiences"</p>
      </header>

      {/* Content Section with Image on Left and Text on Right */}
      <div className="about-content">
        <div className="outer-circle">
          <div className="inner-circle">
            <img src={profile} alt="Profile" className="profile-image" />
          </div>
        </div>
        <div className="text-container">
          <p className="styled-paragraph">
            Welcome to my personal page! Here, you will learn more about me and
            my professional journey. I am committed to creating high-quality
            digital solutions that drive results and enhance user experiences.
          </p>
          <hr className="divider" />
        </div>
      </div>

      {/* New container for centering the list */}
      <div className="details-list-container">
        <ul className="details-list">
          <li>
            <strong>Name</strong> Abdur Rahman
          </li>
          <li>
            <strong>Email</strong>{" "}
            <a href="mailto:abdurrahman@gmail.com">abdurrahman@gmail.com</a>
          </li>
          <li>
            <strong>Phone</strong> 0314 9231046
          </li>
          <li>
            <strong>Date of Birth</strong> July 25, 1998
          </li>
          <li>
            <strong>Nationality</strong> Pakistani
          </li>
        </ul>
      </div>
      <div className="corner-element.top-left"></div>
    </div>
  );
};

export default About;
