import React, { useState, useEffect } from "react";
import axios from "axios";

const GetUserInfo = ({ userId }) => {
  // userId comes from props
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!userId) {
        setError("User ID is not available.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/userInfo/${userId}`, // Use userId from props
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Authorization remains the same
            },
          }
        );
        setUserInfo(response.data.userInfo);
      } catch (err) {
        setError("Error fetching user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div className="flex justify-center items-center min-h-screen text-gray-500">
        No user data available.
      </div>
    );
  }

  return (
    <>
      {userInfo.profileImage ? (
        <div className="text-center mb-4 mt-5">
          <img
            src={`http://localhost:3000/${userInfo.profileImage}`}
            alt="Profile"
            className="w-24 h-24 rounded-full mx-auto"
            style={{
              height: "20%",
              width: "20%",
            }}
          />
        </div>
      ) : (
        <div className="text-center mb-4">
          <p className="text-gray-500">No profile image available.</p>
        </div>
      )}
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">{userInfo.name}</h2>
        <p className="text-gray-600">{userInfo.profession}</p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">{userInfo.address}</p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">Phone: {userInfo.phone}</p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">
          Date of Birth: {new Date(userInfo.dateOfBirth).toLocaleDateString()}
        </p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">
          Website:{" "}
          <a
            href={userInfo.websiteLink}
            className="text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            {userInfo.websiteLink}
          </a>
        </p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">Availability: {userInfo.availability}</p>
      </div>
      <div className="text-center mb-4">
        <p className="text-gray-500">Username: {userInfo.user.username}</p>
        <p className="text-gray-500">Email: {userInfo.user.email}</p>
      </div>
    </>
  );
};

export default GetUserInfo;
