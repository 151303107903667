import { Link } from "react-router-dom";
import logo from "../assets/images/pblogo.svg"; // Adjust the path according to your folder structure
import "../css/navbar.css"; // Make sure to import the CSS file

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light ">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src={logo} // Path to the SVG logo
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Portfolio Builder Logo"
          />
          <span className="brand-title">Portfolio Builder</span>
        </Link>

        {/* Toggler for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Links collapse section */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link home-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link about-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link contact-link" to="/contact">
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link privacy-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>

            {/* Add the "Build Portfolio" button here */}
            <li className="nav-item">
              <Link
                to="/personal-info-upload"
                className="btn btn-primary build-portfolio-button"
              >
                Build Portfolio
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
