import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaTools,
  FaChevronDown,
  FaChevronUp,
  FaCog,
  FaLightbulb,
  FaSave,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import "./css/SkillsUpload.css";

const SkillsUpload = () => {
  const [skills, setSkills] = useState([
    {
      skillTitle: "",
      proficiency: "",
      description: "",
    },
  ]);
  const [fetchedSkills, setFetchedSkills] = useState([]);
  const [collapsed, setCollapsed] = useState([]); // Set collapsed as an empty array initially
  const [editingSkillId, setEditingSkillId] = useState(null);

  // Fetch the skills when component mounts
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      toast.error("User ID not found.");
      return;
    }

    axios
      .get(`http://localhost:3000/skill/${userId}`)
      .then((response) => {
        setFetchedSkills(response.data.skills);
      })
      .catch((error) => {
        // toast.error("Failed to fetch skills.");
      });
  }, []);

  const handleInputChange = (index, field, value) => {
    const newSkills = [...skills];
    newSkills[index][field] = value;
    setSkills(newSkills);
  };

  // Collapse or expand a skill card
  const toggleCollapse = (index) => {
    const newCollapsed = [...collapsed];

    // Make sure the array has an entry for this index
    if (typeof newCollapsed[index] === "undefined") {
      newCollapsed[index] = true; // Default to true if it's undefined
    }

    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  // Edit an existing skill
  const handleEdit = (skillId) => {
    const skill = fetchedSkills.find((s) => s._id === skillId);
    if (skill) {
      setSkills([
        {
          skillTitle: skill.skill,
          proficiency: skill.level,
          description: "", // If you have description in the data, use it here.
        },
      ]);
      setEditingSkillId(skillId);
    }
  };

  // Update collapsed array when the number of skills changes
  useEffect(() => {
    setCollapsed((prevCollapsed) => {
      // Create a new array, filling new entries as true
      const updatedCollapsed = new Array(skills.length)
        .fill(true)
        .map((_, i) => prevCollapsed[i] ?? true); // Use previous value if it exists
      return updatedCollapsed;
    });
  }, [skills.length]);

  // Save or update a skill
  const handleSave = async (index) => {
    const skill = skills[index];

    if (!skill.skillTitle || !skill.proficiency) {
      toast.error("Please fill in both skill and proficiency.");
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      toast.error("User is not authenticated.");
      return;
    }

    const skillData = {
      userId,
      skill: skill.skillTitle,
      level: skill.proficiency,
    };

    try {
      let response;
      if (editingSkillId) {
        // Update existing skill
        response = await axios.put(
          `http://localhost:3000/skill/${editingSkillId}`,
          skillData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Skill updated successfully!");
      } else {
        // Save new skill
        response = await axios.post("http://localhost:3000/skill", skillData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Skill saved successfully!");
      }

      setFetchedSkills((prevSkills) => {
        if (editingSkillId) {
          return prevSkills.map((s) =>
            s._id === editingSkillId ? response.data.skill : s
          );
        } else {
          return [...prevSkills, response.data.skill];
        }
      });
      setSkills([{ skillTitle: "", proficiency: "", description: "" }]);
      setEditingSkillId(null);
    } catch (err) {
      toast.error("Failed to save skill. Please try again.");
    }
  };

  // Delete a skill
  const handleDelete = async (skillId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("User is not authenticated.");
      return;
    }

    try {
      await axios.delete(`http://localhost:3000/skill/${skillId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Skill deleted successfully!");

      setFetchedSkills((prevSkills) =>
        prevSkills.filter((skill) => skill._id !== skillId)
      );
    } catch (err) {
      toast.error("Failed to delete skill. Please try again.");
    }
  };

  return (
    <div className="center-container">
      {skills.map((skill, index) => (
        <div key={index} className="skill-card">
          <div
            className="skill-header"
            onClick={() => {
              if (collapsed[index] !== undefined) {
                toggleCollapse(index);
              }
            }}
          >
            <div className="skill-title">
              <FaTools className="icon" />
              Skills
            </div>
            <div className="skill-actions">
              <FaCog className="icon settings-icon" />
              {collapsed[index] ? (
                <FaChevronDown className="icon" />
              ) : (
                <FaChevronUp className="icon" />
              )}
            </div>
          </div>

          <div className={`collapse ${collapsed[index] ? "" : "show"}`}>
            <div className="card-body">
              <form>
                <hr />
                {fetchedSkills.length > 0 && (
                  <div className="fetched-skills-list">
                    {fetchedSkills.map((fetchedSkill) => (
                      <div key={fetchedSkill._id} className="fetched-skill-row">
                        <div className="skill-name">{fetchedSkill.skill}</div>
                        <div className="skill-actions">
                          <FaEdit
                            className="icon edit-icon"
                            onClick={() => handleEdit(fetchedSkill._id)}
                          />
                          <FaTrash
                            className="icon delete-icon"
                            onClick={() => handleDelete(fetchedSkill._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="row mt-2">
                  <div className="col">
                    <label htmlFor={`skillTitle-${index}`}>Skill</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`skillTitle-${index}`}
                      placeholder="e.g. JavaScript"
                      value={skill.skillTitle}
                      onChange={(e) =>
                        handleInputChange(index, "skillTitle", e.target.value)
                      }
                    />
                  </div>
                  <div className="col">
                    <label htmlFor={`proficiency-${index}`}>Proficiency</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`proficiency-${index}`}
                      placeholder="e.g. Intermediate"
                      value={skill.proficiency}
                      onChange={(e) =>
                        handleInputChange(index, "proficiency", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn"
                      style={{ border: "solid" }}
                    >
                      <FaLightbulb className="icon" /> Tips
                    </button>
                    <div>
                      <button
                        type="button"
                        className="btn"
                        style={{ border: "solid" }}
                        onClick={() => handleSave(index)}
                      >
                        <FaSave className="icon" />{" "}
                        {editingSkillId ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ))}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default SkillsUpload;
