import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/projectsUpload.css"; // Assuming a separate CSS file for styles
import { FaPlus, FaLightbulb, FaSave } from "react-icons/fa"; // Importing icons

const ProjectsUpload = () => {
  const navigate = useNavigate();

  const [projects, setProjects] = useState([
    {
      title: "",
      mainImage: null,
      moreImages: [],
      shortDescription: "",
      fullDescription: "",
      startDate: "",
      endDate: "",
      projectLink: "",
    },
  ]);

  const handleAddProject = () => {
    setProjects([
      ...projects,
      {
        title: "",
        mainImage: null,
        moreImages: [],
        shortDescription: "",
        fullDescription: "",
        startDate: "",
        endDate: "",
        projectLink: "",
      },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedProjects = [...projects];
    updatedProjects[index][field] = value;
    setProjects(updatedProjects);
  };

  const handleSaveProject = (index) => {
    console.log("Saving project:", projects[index]);
    // Add logic to save the project
  };

  return (
    <div className="projects-container">
      {/* Heading */}
      <h1 className="projects-heading">Tell us about your Projects</h1>
      <p className="projects-subtitle">
        Please provide details about the projects you have worked on.
      </p>

      {projects.map((project, index) => (
        <div key={index} className="project-entry">
          {/* Project Title and Main Image */}
          <div className="project-row">
            <div className="project-field">
              <label htmlFor={`project-title-${index}`}>Project Title</label>
              <input
                type="text"
                id={`project-title-${index}`}
                value={project.title}
                onChange={(e) => handleChange(index, "title", e.target.value)}
                placeholder="Enter project title"
              />
            </div>
            <div className="project-field">
              <label htmlFor={`project-main-image-${index}`}>
                Project Main Image
              </label>
              <input
                type="file"
                id={`project-main-image-${index}`}
                onChange={(e) =>
                  handleChange(index, "mainImage", e.target.files[0])
                }
              />
            </div>
          </div>

          {/* Project More Images */}
          <div className="project-row">
            <div className="project-field">
              <label htmlFor={`project-more-images-${index}`}>
                Project More Images
              </label>
              <input
                type="file"
                id={`project-more-images-${index}`}
                multiple
                onChange={(e) =>
                  handleChange(index, "moreImages", e.target.files)
                }
              />
            </div>
          </div>

          {/* Short Description and Full Description */}
          <div className="project-row">
            <div className="project-field">
              <label htmlFor={`short-description-${index}`}>
                Short Description
              </label>
              <textarea
                id={`short-description-${index}`}
                rows="2"
                value={project.shortDescription}
                onChange={(e) =>
                  handleChange(index, "shortDescription", e.target.value)
                }
                placeholder="Enter a brief description"
              />
            </div>
          </div>

          <div className="project-row">
            <div className="project-field">
              <label htmlFor={`full-description-${index}`}>
                Full Description
              </label>
              <textarea
                id={`full-description-${index}`}
                rows="5"
                value={project.fullDescription}
                onChange={(e) =>
                  handleChange(index, "fullDescription", e.target.value)
                }
                placeholder="Enter full project details"
              />
            </div>
          </div>

          {/* Project Start Date and End Date */}
          <div className="project-row">
            <div className="project-field">
              <label>Start Date</label>
              <input
                type="date"
                value={project.startDate}
                onChange={(e) =>
                  handleChange(index, "startDate", e.target.value)
                }
              />
            </div>
            <div className="project-field">
              <label>End Date</label>
              <input
                type="date"
                value={project.endDate}
                onChange={(e) => handleChange(index, "endDate", e.target.value)}
              />
            </div>
          </div>

          {/* Project Link */}
          <div className="project-row">
            <div className="project-field">
              <label htmlFor={`project-link-${index}`}>Project Link</label>
              <input
                type="url"
                id={`project-link-${index}`}
                value={project.projectLink}
                onChange={(e) =>
                  handleChange(index, "projectLink", e.target.value)
                }
                placeholder="Enter project link"
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="save-education-button">
            <button
              onClick={() => handleSaveProject(index)}
              className="save-button"
            >
              <FaSave className="icon" /> Save Project
            </button>
          </div>

          <hr />
        </div>
      ))}

      {/* Add Another Project Button */}
      <div className="add-project-button">
        <button onClick={handleAddProject}>
          <FaPlus className="icon" /> Add Another Project
        </button>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/skills-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/services-upload")}
        >
          Next: Services
        </button>
      </div>
    </div>
  );
};

export default ProjectsUpload;
