import React from "react";
import "../css/Navbar.css";

const Navbar = ({ linkColor }) => {
  return (
    <nav className="navbar">
      <div className="navbar-name">Alex Smith</div>
      <div className="navbar-links ">
        <a href="/temp1-home" style={{ color: linkColor }}>
          Home
        </a>
        <span>|</span>
        <a href="/temp1-about" style={{ color: linkColor }}>
          About Us
        </a>
        <span>|</span>
        <a href="#services" style={{ color: linkColor }}>
          Services
        </a>
        <span>|</span>
        <a href="#portfolio" style={{ color: linkColor }}>
          Portfolio
        </a>
        <span>|</span>
        <a href="#contact" style={{ color: linkColor }}>
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
