import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import "../css/experienceInfoUpload.css"; // Assuming a separate CSS file
import { FaPlus, FaLightbulb, FaSave } from "react-icons/fa"; // Importing icons

const ExperienceInfoUpload = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [experiences, setExperiences] = useState([
    {
      jobTitle: "",
      companyName: "",
      remote: false,
      country: "",
      city: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      currentlyWorkHere: false,
    },
  ]);

  const handleAddAnotherExperience = () => {
    setExperiences([
      ...experiences,
      {
        jobTitle: "",
        companyName: "",
        remote: false,
        country: "",
        city: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        currentlyWorkHere: false,
      },
    ]);
  };

  const handleChangeExperience = (index, field, value) => {
    const updatedExperiences = experiences.map((exp, i) =>
      i === index ? { ...exp, [field]: value } : exp
    );
    setExperiences(updatedExperiences);
  };

  const handleSaveExperience = (index) => {
    console.log("Saving experience:", experiences[index]);
    // Add logic to save experience
  };

  return (
    <div className="experience-container">
      {/* Heading */}
      <h1 className="experience-heading">Tell us about your most recent job</h1>

      {/* Subtitle */}
      <p className="experience-subtitle">
        We’ll start there and work backward.{" "}
      </p>

      {experiences.map((experience, index) => (
        <div key={index} className="experience-item">
          {/* First Row: Job Title and Company/Organization Name */}
          <div className="experience-row">
            <div className="experience-field">
              <label htmlFor={`jobTitle-${index}`}>Job Title</label>
              <input
                type="text"
                id={`jobTitle-${index}`}
                value={experience.jobTitle}
                onChange={(e) =>
                  handleChangeExperience(index, "jobTitle", e.target.value)
                }
                placeholder="Enter your job title"
              />
            </div>
            <div className="experience-field">
              <label htmlFor={`companyName-${index}`}>
                Company/Organization Name
              </label>
              <input
                type="text"
                id={`companyName-${index}`}
                value={experience.companyName}
                onChange={(e) =>
                  handleChangeExperience(index, "companyName", e.target.value)
                }
                placeholder="Enter your company name"
              />
            </div>
          </div>

          {/* Remote Checkbox */}
          <div className="experience-field">
            <label>
              <input
                type="checkbox"
                checked={experience.remote}
                onChange={(e) =>
                  handleChangeExperience(index, "remote", e.target.checked)
                }
              />
              Remote
            </label>
          </div>

          {/* Second Row: Country and City/Town */}
          <div className="experience-row">
            <div className="experience-field">
              <label htmlFor={`country-${index}`}>Country</label>
              <input
                type="text"
                id={`country-${index}`}
                value={experience.country}
                onChange={(e) =>
                  handleChangeExperience(index, "country", e.target.value)
                }
                placeholder="Enter your country"
              />
            </div>
            <div className="experience-field">
              <label htmlFor={`city-${index}`}>City/Town</label>
              <input
                type="text"
                id={`city-${index}`}
                value={experience.city}
                onChange={(e) =>
                  handleChangeExperience(index, "city", e.target.value)
                }
                placeholder="Enter your city/town"
              />
            </div>
          </div>

          {/* Third Row: Start Date and End Date */}
          <div className="experience-row">
            <div className="experience-field">
              <label>Start Date</label>
              <div className="date-fields">
                <select
                  value={experience.startMonth}
                  onChange={(e) =>
                    handleChangeExperience(index, "startMonth", e.target.value)
                  }
                >
                  <option value="">Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  {/* Add other months */}
                </select>
                <select
                  value={experience.startYear}
                  onChange={(e) =>
                    handleChangeExperience(index, "startYear", e.target.value)
                  }
                >
                  <option value="">Year</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  {/* Add other years */}
                </select>
              </div>
            </div>
            <div className="experience-field">
              <label>End Date</label>
              <div className="date-fields">
                <select
                  value={experience.endMonth}
                  onChange={(e) =>
                    handleChangeExperience(index, "endMonth", e.target.value)
                  }
                  disabled={experience.currentlyWorkHere}
                >
                  <option value="">Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  {/* Add other months */}
                </select>
                <select
                  value={experience.endYear}
                  onChange={(e) =>
                    handleChangeExperience(index, "endYear", e.target.value)
                  }
                  disabled={experience.currentlyWorkHere}
                >
                  <option value="">Year</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  {/* Add other years */}
                </select>
              </div>
            </div>
          </div>

          {/* Currently Work Here Checkbox */}
          <div className="experience-field">
            <label>
              <input
                type="checkbox"
                checked={experience.currentlyWorkHere}
                onChange={(e) =>
                  handleChangeExperience(
                    index,
                    "currentlyWorkHere",
                    e.target.checked
                  )
                }
              />
              I currently work here
            </label>
          </div>

          {/* Save Button */}

          <div className="save-education-button">
            <button
              onClick={() => handleSaveExperience(index)}
              className="save-button"
            >
              <FaSave className="icon" /> Save Experience
            </button>
          </div>

          {/* Horizontal Divider */}
          <hr className="experience-divider" />
        </div>
      ))}

      {/* Add Another Experience Button */}
      <div className="add-experience-button">
        <button onClick={handleAddAnotherExperience}>
          <FaPlus className="icon" /> Add Another Experience
        </button>
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => navigate("/education-upload")}
        >
          Back
        </button>
        <button
          className="next-button"
          onClick={() => navigate("/summary-upload")}
        >
          Next: Summary
        </button>
      </div>
    </div>
  );
};

export default ExperienceInfoUpload;
