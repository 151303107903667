import React from "react";
import { Link, useNavigate } from "react-router-dom";

const TemplateSelection = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user data (e.g., token, userId) from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    // Redirect to login page
    navigate("/login");
  };

  return (
    <div>
      <div className="container mt-4">
        <div className="row d-flex justify-content-between">
          <div className="col-sm-4 mb-4">
            <div className="card" style={{ width: "18rem" }}>
              <div className="card-body">
                <h5 className="card-title">Light Template</h5>

                <Link to="/temp1-home" className="btn btn-primary">
                  Select Light
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div className="card" style={{ width: "18rem" }}>
              <div className="card-body">
                <h5 className="card-title">Dark Template</h5>

                <Link to="/temp2-home" className="btn btn-primary">
                  Select Dark
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div className="card" style={{ width: "18rem" }}>
              <div className="card-body">
                <h5 className="card-title">Hybrid Template</h5>

                <Link to="/temp3-home" className="btn btn-primary">
                  Select Hybrid
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn btn-danger" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateSelection;
